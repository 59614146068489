import FadeInLetters from '../FadeInLetters'
import './index.scss'
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPython, faJava, faGitAlt, faLinux, faJsSquare, faReact } from '@fortawesome/free-brands-svg-icons';
import Loader from 'react-loaders'

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])
    return (
        <>

            <div className='container about-page'>
                <div className='text-zone'>
                    <h1>
                        <FadeInLetters
                            letterClass={letterClass}
                            strArray={['A', 'b', 'o', 'u', 't', ' ', 'M', "e"]}
                            idx={5}
                        />

                    </h1>
                    
                        <p>I'm a metro Atlanta based recent graduate from Kennesaw State University with a degree in Software Engineering.</p>
                    <p>In my time as a software engineer, I've gained experience in a few programming languages and technologies. I'm most familiar with <span classname="java">Java</span>, <span classname="python">Python</span>, <span classname="javascript ">Javascript,</span> and <span classname="react ">React</span>. I also have a solid understanding of Linux, SQL and a growing understanding of .NET.</p>
                        <p>I'm excited to bolster and apply my skills and knowledge in a professional setting and am always interested in opportunities to contribute to exciting projects and grow as an engineer.</p>
                    

                   

                </div>
                <div className='stage-cube-cont'>
                    <div className='cubespinner'>
                        <div className='face1'>
                            <FontAwesomeIcon icon={faPython} color='#646464' />
                        </div>
                        <div className='face2'>
                            <FontAwesomeIcon icon={faReact} color='#5ED4F4' />
                        </div>
                        <div className='face3'>
                            <FontAwesomeIcon icon={faLinux} color='#FFF' />
                        </div>
                        <div className='face4'>
                            <FontAwesomeIcon icon={faJsSquare} color='#EFD81D' />
                        </div>
                        <div className='face5'>
                            <FontAwesomeIcon icon={faGitAlt} color='#F1502F' />
                        </div>
                        <div className='face6'>
                            <FontAwesomeIcon icon={faJava} color='#f89820' />
                        </div>

                    </div>

                </div>
            </div>
            <Loader type='ball-pulse-sync' />
        </>

    )
}

export default About