import { Link, NavLink } from 'react-router-dom'
import './index.scss'
import LogoPlacehold from '../../assets/images/logo-placehold.png'
import LogoSubtitlePlace from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faEnvelope,faSheetPlastic } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'

const Sidebar = () => (
  <div className="nav-bar">
    <Link className="logo" to="/">
      <img src={LogoPlacehold} alt="logo" />
      <img classname="sub-logo" src={LogoSubtitlePlace} alt="stephan.dev" />
    </Link>
    <nav>
      <NavLink exact="true" activeclassname="active" className="home-link" to="/">
        <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
      </NavLink>
      <NavLink exact="true" activeclassname="active" className="about-link" to="/about">
        <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
      </NavLink>
      <NavLink exact="true" activeclassname="active" className="projects-link" to="/projects">
        <FontAwesomeIcon icon={faSheetPlastic} color="#4d4d4e" />
      </NavLink>
      <NavLink exact="true" activeclassname="active" className="contact-link" to="/contact">
        <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
      </NavLink>

    </nav>
    <ul>
      <li>
        <a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/in/1stephan/'>
          <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
        </a>

        <a target="_blank" rel='noopener noreferrer' href='https://github.com/stephanmah'>
          <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
        </a>

      </li>

    </ul>

  </div>
)

export default Sidebar
