import './index.scss'
import Loader from 'react-loaders'
import { Link } from 'react-router-dom'
import FadeInLetters from "../FadeInLetters";
import { useEffect, useState } from 'react';
import NBADA_Logo from '../../assets/images/nbada_logo.png';
import Focus_Timer from "../../assets/images/focus_timer.png"
import wipGlobe_Logo from '../../assets/images/earth simulation wip.png';
import tbd_Placeholder from '../../assets/images/tbd.png';



const Projects = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000);
    }, [])

    return (
        <>
            <div className="container projects-page">
                <div className="text-zone">
                    <h1>
                        <FadeInLetters
                            letterClass={letterClass}
                            strArray={['P', 'r', 'o', 'j', 'e', 'c', 't', 's']}
                            idx={5}

                        />
                    </h1>

                </div>

                <div className="projects-box">
                    <div className='nba-data'>

                        < a href="https://nba-data-analyzer.streamlit.app/" target="_blank">  <img className="nba-logo" src={NBADA_Logo} alt="nba data analyzer" /></a>

                        <Link to="https://nba-data-analyzer.streamlit.app/" className="flat-button"> NBA Data Analyzer </Link> 
                        
                        <p className='project-descs'>
                            NBA Data Analyzer is basic web app that allows you to  quickly find stats from any current NBA player and team. <br /><br />
                            It is made using Python, Streamlit, and the NBA API by swar. <br />
                            There is a good chance the app will be asleep when you visit, feel free to get it up to take a look.

                        </p>


                    </div>

                    <div className='focus-timer'>
                        <a href="" target='_blank'> <img className="focus-logo" src={Focus_Timer} alt="pomodoro timer" /></a>
                        
                        <Link to="/projects" className="flat-button" target="_blank"> Focus Timer</Link>
                        
                        <p className='project-descs'>
                            The focus timer is a simple pomodoro timer used to manage time when working.<br /><br />
                            Any project I've made since the timer was made with its assistance, proving itself to be very useful.<br />
                            It was made using react and sass

                        </p>



                    </div>
                    <div className='globe-simulation'>
                        <a href="" target='_blank'> </a>
                        <img className="globe-logo" src={wipGlobe_Logo} alt="globe sim" />
                        <Link to="" className="flat-button"> Globe Simulation</Link>
                        
                        <p className='project-descs'>
                            The Globe Simulator is a 3d visualization of the Earth made using ThreeJS.<br /><br />
                             Admire our pale blue dot and gain a deeper appreciation for the planet we call home.
                        </p>


                    </div>
                    <div className='project4'>
                        <a href="" target='_blank'> </a>
                        <img className="tbd-logo" src={tbd_Placeholder} alt="bd" />
                        <Link to="" className="flat-button"> To be Announced</Link>
                        
                        <p className='project-descs'>
                            This is a placeholder for a larger, collaborative project I'm working on with friends
                        </p>


                    </div>
                </div>

                <div className='project-descs'>

                </div>

            </div>
            <Loader type='ball-pulse-sync' />
        </>
    )

}

export default Projects