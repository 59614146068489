import './index.scss'
import Loader from 'react-loaders'
import FadeInLetters from '../../../FadeInLetters'
import { useState, useEffect, useRef } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';


const Timer = () => {














    return (

        <>
            <div className='container timer'>

                <h1 >TESTING</h1>
                <Paper square={false} elevation={24}>
                    
                </Paper>








            </div>











        </>



    )


}

export default Timer